import React from 'react'
import "./AboutSec1.css";

const ServiceSec1 = () => {
  return (
    
    <section class="section-box wow animate__animated animate__fadeIn box-we-know" style={{marginTop:"4%"}}>
    <div class="bg-we-know"></div>
    <div class="container">
      <div class="row align-items-end justify-content-between"> 
        <div class="col-lg-7 mb-30"> 
          <h2 class="display-2 neutral-0">We know how to manage business globally</h2>
        </div>
        <div class="col-lg-4 mb-30"> 
          <h6 class="neutral-0">Do you have more question about our solution? Just send us a message for help</h6>
        </div>
      </div>
      <div class="row mt-65"> 
        <div class="col-lg-4 col-md-6"> 
          <div class="card-we-know"> 
            <div class="card-image"> <img src="assets/imgs/page/services/img.png" alt="Nivia"/></div>
            <div class="card-info"> <a class="card-title" href="#"> 
                <h4>Business Solution</h4>
                <svg width="38" height="38" viewbox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="38" height="38" rx="19" fill=""></rect>
                  <g clip-path="url(#clip0_517_2603)">
                    <path d="M23.6537 16.8149L14.718 25.7506L13.25 24.2826L22.1847 15.3469H14.31V13.2705H25.7301V24.6906H23.6537V16.8149Z" fill="#C5FF55"></path>
                  </g>
                  <defs>
                    <clippath id="clip0_517_2603">
                      <rect width="13" height="13" fill="white" transform="translate(13 13)"></rect>
                    </clippath>
                  </defs>
                </svg></a>
              <div class="card-desc"> 
                <p class="text-md">Purus dui eget sollicitudin curae leo proin platea cras, morbi torquent massa</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6"> 
          <div class="card-we-know"> 
            <div class="card-image"> <img src="assets/imgs/page/services/img2.png" alt="Nivia"/></div>
            <div class="card-info"> <a class="card-title" href="#"> 
                <h4>Business Solution</h4>
                <svg width="38" height="38" viewbox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="38" height="38" rx="19" fill=""></rect>
                  <g clip-path="url(#clip0_517_2603)">
                    <path d="M23.6537 16.8149L14.718 25.7506L13.25 24.2826L22.1847 15.3469H14.31V13.2705H25.7301V24.6906H23.6537V16.8149Z" fill="#C5FF55"></path>
                  </g>
                  <defs>
                    <clippath id="clip0_517_2603">
                      <rect width="13" height="13" fill="white" transform="translate(13 13)"></rect>
                    </clippath>
                  </defs>
                </svg></a>
              <div class="card-desc"> 
                <p class="text-md">Purus dui eget sollicitudin curae leo proin platea cras, morbi torquent massa</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6"> 
          <div class="card-we-know"> 
            <div class="card-image"> <img src="assets/imgs/page/services/img3.png" alt="Nivia"/></div>
            <div class="card-info"> <a class="card-title" href="#"> 
                <h4>Business Solution</h4>
                <svg width="38" height="38" viewbox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="38" height="38" rx="19" fill=""></rect>
                  <g clip-path="url(#clip0_517_2603)">
                    <path d="M23.6537 16.8149L14.718 25.7506L13.25 24.2826L22.1847 15.3469H14.31V13.2705H25.7301V24.6906H23.6537V16.8149Z" fill="#C5FF55"></path>
                  </g>
                  <defs>
                    <clippath id="clip0_517_2603">
                      <rect width="13" height="13" fill="white" transform="translate(13 13)"></rect>
                    </clippath>
                  </defs>
                </svg></a>
              <div class="card-desc"> 
                <p class="text-md">Purus dui eget sollicitudin curae leo proin platea cras, morbi torquent massa</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6"> 
          <div class="card-we-know"> 
            <div class="card-image"> <img src="assets/imgs/page/services/img4.png" alt="Nivia"/></div>
            <div class="card-info"> <a class="card-title" href="#"> 
                <h4>Business Solution</h4>
                <svg width="38" height="38" viewbox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="38" height="38" rx="19" fill=""></rect>
                  <g clip-path="url(#clip0_517_2603)">
                    <path d="M23.6537 16.8149L14.718 25.7506L13.25 24.2826L22.1847 15.3469H14.31V13.2705H25.7301V24.6906H23.6537V16.8149Z" fill="#C5FF55"></path>
                  </g>
                  <defs>
                    <clippath id="clip0_517_2603">
                      <rect width="13" height="13" fill="white" transform="translate(13 13)"></rect>
                    </clippath>
                  </defs>
                </svg></a>
              <div class="card-desc"> 
                <p class="text-md">Purus dui eget sollicitudin curae leo proin platea cras, morbi torquent massa</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6"> 
          <div class="card-we-know"> 
            <div class="card-image"> <img src="assets/imgs/page/services/img5.png" alt="Nivia"/></div>
            <div class="card-info"> <a class="card-title" href="#"> 
                <h4>Business Solution</h4>
                <svg width="38" height="38" viewbox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="38" height="38" rx="19" fill=""></rect>
                  <g clip-path="url(#clip0_517_2603)">
                    <path d="M23.6537 16.8149L14.718 25.7506L13.25 24.2826L22.1847 15.3469H14.31V13.2705H25.7301V24.6906H23.6537V16.8149Z" fill="#C5FF55"></path>
                  </g>
                  <defs>
                    <clippath id="clip0_517_2603">
                      <rect width="13" height="13" fill="white" transform="translate(13 13)"></rect>
                    </clippath>
                  </defs>
                </svg></a>
              <div class="card-desc"> 
                <p class="text-md">Purus dui eget sollicitudin curae leo proin platea cras, morbi torquent massa</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6"> 
          <div class="card-we-know"> 
            <div class="card-image"> <img src="assets/imgs/page/services/img6.png" alt="Nivia"/></div>
            <div class="card-info"> <a class="card-title" href="#"> 
                <h4>Business Solution</h4>
                <svg width="38" height="38" viewbox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="38" height="38" rx="19" fill=""></rect>
                  <g clip-path="url(#clip0_517_2603)">
                    <path d="M23.6537 16.8149L14.718 25.7506L13.25 24.2826L22.1847 15.3469H14.31V13.2705H25.7301V24.6906H23.6537V16.8149Z" fill="#C5FF55"></path>
                  </g>
                  <defs>
                    <clippath id="clip0_517_2603">
                      <rect width="13" height="13" fill="white" transform="translate(13 13)"></rect>
                    </clippath>
                  </defs>
                </svg></a>
              <div class="card-desc"> 
                <p class="text-md">Purus dui eget sollicitudin curae leo proin platea cras, morbi torquent massa</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ServiceSec1
